import { StrapiTextualProps } from '@/features/strapi/components/textual';

export const CustomersContent = {
  title: {
    size: `5.2rem`,
    weight: `bold`,
    text: `Powering the most innovative\nShopify brands`,
    mobileText: `Powering the most\n innovative\nShopify brands`,
    mobileMultiplier: 0.75,
    smMobileMultiplier: 0.68,
  } as StrapiTextualProps,
  subtitle: {
    lineHeight: `1.8`,
    size: `2rem`,
    weight: `normal`,
    mobileMultiplier: 0.85,
    text: `A/B test, Optimize, and Personalize every step of the way.
    Zero code, a ton of sales.`,
    mobileText: `A/B test, Optimize, and Personalize every step.
    Zero code, a ton of sales.`,
  } as StrapiTextualProps,
  caseStudiesTitle: {
    size: `5.2rem`,
    weight: `bold`,
    text: `Check our customer success stories`,
    mobileText: `Check our customer\n success stories`,
    mobileMultiplier: 0.75,
    smMobileMultiplier: 0.68,
  } as StrapiTextualProps,
  caseStudies: [
    {
      title: `How Canopy Increased Conversion Rate By 33% In Just 3 Months`,
      link: `/public/case-studies/canopy`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/Artboard.png`,
    },
    {
      title: `Faherty Adopts Visually As Their CRO Platform`,
      link: `/public/case-studies/faherty`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/Artboard%20Copy%2029.png`,
    },
    {
      title: `How The Oodie Reimagined CRO & A/B Testing With Visually`,
      link: `/public/case-studies/the-oodie`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/casestudies/theoodie.png`,
    },
    {
      title: `How Bonafide Redefined Onsite Personalization With Visually`,
      link: `/public/case-studies/bonfide`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/casestudies/bonfide.png`,
    },
    {
      title: `How Branch Generated +900X ROI With Visually`,
      link: `/public/case-studies/branch`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/casestudies/branch.png`,
    },
    {
      title: `How Fresh Clean Threads Increased Mobile Conversion Rate By 35%`,
      link: `/public/case-studies/fct`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/casestudies/fct.png`,
    },
    {
      title: `Underoutfit boosts CVR by 42%`,
      link: `/public/case-studies/underoutfit`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/1-2.png?width=700&name=1-2.png`,
    },
    {
      title: `RIP Google Optimize. Why Visually Was Perfect For The Perfect Jean`,
      link: `/public/case-studies/tpj`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/2-4.png?width=700&name=2-4.png`,
    },
  ],
};

export const CaseStudiesPages = {
  'The Perfect Jean': `https://143410811.hs-sites-eu1.com/branch-0`,
  Underoutfit: `https://143410811.hs-sites-eu1.com/en/underoutfit_casestudy_visually`,
  Canopy: `https://143410811.hs-sites-eu1.com/canopy`,
  Faherty: `https://143410811.hs-sites-eu1.com/faherty`,
  'The Oodie': `https://143410811.hs-sites-eu1.com/canopy-0-0`,
  'Fresh Clean Threads': `https://143410811.hs-sites-eu1.com/branch-0-0-0`,
  Bonfide: `https://143410811.hs-sites-eu1.com/bonafide-1`,
  Branch: `https://143410811.hs-sites-eu1.com/branch-1`,
};
